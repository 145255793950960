// import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { HashLink } from "react-router-hash-link";

// import classes from './MainNavigation.module.css';

export default function GuestFooter() {
  const currentYear = new Date().getFullYear();

  return (
    <footer>
      <Container>
        <Row id="footernav">
          <Col lg={2}>
            <h3>Features</h3>
            <ul>
              <li>
                <a
                  href="/free-teleconferencing-service"
                  title="Free Teleconferencing"
                >
                  Free Teleconferencing
                </a>
              </li>
              <li>
                <a href="/online-meetings" title="Screen Sharing">
                  Screen Sharing
                </a>
              </li>
              <li>
                <a href="/video-conferencing" title="Video Conferencing">
                  Video Conferencing
                </a>
              </li>
              <li>
                <a
                  href="/international-conferencing"
                  title="International Conferencing"
                >
                  International Conferencing
                </a>
              </li>
            </ul>
          </Col>
          <Col lg={2}>
            <h3>Solutions</h3>
            <ul>
              <li>
                <a href="/for-business" title="For Business">
                  For Business
                </a>
              </li>
              <li>
                <a href="/for-podcasts" title="For Podcasters">
                  For Podcasters
                </a>
              </li>
              <li>
                <a href="/for-education" title="For Education">
                  For Education
                </a>
              </li>
              <li>
                <a href="/for-developers" title="For Developers">
                  For Developers
                </a>
              </li>
              <li>
                <a href="/high-volume" title="Large Meetings">
                  Large Meetings
                </a>
              </li>
            </ul>
          </Col>
          <Col lg={2}>
            <h3>Support</h3>
            <ul>
              <li>
                <a href="/support" title="Support Center">
                  Support Center
                </a>
              </li>
              <li>
                <a href="/support-ticket" title="Support Tickets">
                  Support Ticket
                </a>
              </li>
              <li>
                <a href="/downloads" title="Downloads">
                  Downloads
                </a>
              </li>
            </ul>
          </Col>
          <Col lg={2}>
            <h3>Company</h3>
            <ul>
              <li>
                <a href="/aboutus" title="About Us">
                  About Us
                </a>
              </li>
              <li>
                <HashLink
                  to="/aboutus#executives"
                  title="Executives"
                  scroll={(el) => {
                    const headerHeight = document.querySelector('header')?.clientHeight || 0;
                    const y = el.getBoundingClientRect().top + window.scrollY - headerHeight;
                    window.scrollTo({ top: y, behavior: 'smooth' });
                  }}
                >
                  Executives
                </HashLink>
              </li>
              <li>
                <a href="/careers" title="Careers">
                  Careers
                </a>
              </li>
              <li>
                <a href="/contact-us" title="Contact Us">
                  Contact Us
                </a>
              </li>
            </ul>
          </Col>
          <Col lg={2}>
            <h3>Stay Connected</h3>
            <ul>
              <li>
                <a href="/press-center" title="Press Center">
                  Press Center
                </a>
              </li>
              <li>
                <a href="/reviews" title="Reviews">
                  Reviews
                </a>
              </li>
              <li>
                <a href="/resource-center" title="Blog">
                  Blog
                </a>
              </li>
            </ul>
          </Col>
        </Row>
        <hr />
        <div className="text-center social-icons">
          <a
            className="youtube"
            title="Youtube"
            href="https://www.youtube.com/user/freeconf"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              role="img"
            >
              <path d="M186.8 202.1l95.2 54.1-95.2 54.1V202.1zM448 80v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48zm-42 176.3s0-59.6-7.6-88.2c-4.2-15.8-16.5-28.2-32.2-32.4C337.9 128 224 128 224 128s-113.9 0-142.2 7.7c-15.7 4.2-28 16.6-32.2 32.4-7.6 28.5-7.6 88.2-7.6 88.2s0 59.6 7.6 88.2c4.2 15.8 16.5 27.7 32.2 31.9C110.1 384 224 384 224 384s113.9 0 142.2-7.7c15.7-4.2 28-16.1 32.2-31.9 7.6-28.5 7.6-88.1 7.6-88.1z" />
            </svg>
          </a>
          <a
            className="linkedin"
            title="LinkedIn"
            href="https://www.linkedin.com/company/freeconferencecall.com"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              role="img"
            >
              <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
            </svg>
          </a>
        </div>
        <Row>
          <Col md={12}>
            <div className="small" id="sitelegend">
              <ul>
                <li>
                  <a href="/privacy-policy" title="Privacy Policy">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a href="/sitemap" title="Sitemap">
                    Sitemap
                  </a>
                </li>
                <li>
                  <a href="/phone/acceptableuse" title="Acceptable Use">
                    Acceptable Use
                  </a>
                </li>
                <li>
                  <a href="/terms-of-service" title="Terms & Conditions">
                    Terms & Conditions
                  </a>
                </li>
                <li>
                  <a
                    href="/terms-and-conditions-us-gov-agencies"
                    title="Terms and Conditions for US Government Agencies"
                  >
                    Terms and Conditions for US Government Agencies
                  </a>
                </li>
                <li id="company-info">
                  © FreeConferenceCall.com 2001-{currentYear}, ver G
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
