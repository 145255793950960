// import Container from 'react-bootstrap/Container';

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Button from "react-bootstrap/Button";

import { useSession } from "../lib/SessionProvider";
import { useModal } from "../lib/ModalProvider";

export default function LoggedInMainNavigation() {
  const { session } = useSession();
  const subscription = session.subscription;
  const meeting_url = subscription.meeting_url;
  const host_url = `/wall/${meeting_url}/host/start_application`;

  const first_name = subscription.first_name;
  const last_name = subscription.last_name;
  const full_name =
    first_name || last_name
      ? [first_name, last_name].filter((s) => typeof s === "string").join(" ")
      : "Anonymous";

  const { openModal } = useModal();

  function openJoinModal() {
    openModal("join");
  }

  return (
    <Navbar expand="lg" className="fixed-top main-navigation">
      <Navbar.Brand href="/profile" className="ms-2">
        <img
          height="35"
          className="d-inline-block align-top"
          src="https://www.freeconferencecall.com/images/application_wide/general/logo/freeconferencecall-logo.svg"
          alt="FreeConferenceCall.com"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" className="me-auto" />
      <div className="d-flex order-lg-2">
        <Button
          href={host_url}
          variant="outline-light"
          className="me-3 d-none d-lg-block"
        >
          Host
        </Button>
        <Button
          onClick={openJoinModal}
          variant="outline-light"
          className="me-3 d-none d-lg-block"
        >
          Join
        </Button>
        <NavDropdown
          id="profile-nav-dropdown"
          align="end"
          className="profile-menu"
          title={
            <>
              <img
                src="https://www.freeconferencecall.com/images/Headshot_new.png"
                alt="Avatar"
              />
              {full_name}
            </>
          }
        >
          <NavDropdown.Item
            as="div"
            className="d-flex align-items-center profile-item"
          >
            <img
              src="https://www.freeconferencecall.com/images/Headshot_new.png"
              alt="User Avatar"
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                marginRight: "15px",
              }}
            />
            <div>
              <div>{full_name}</div>
              <div style={{ fontSize: "0.85rem", color: "#8993a4" }}>
                {subscription.email}
              </div>
            </div>
          </NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item href="/profile/settings">
            My Profile Settings
          </NavDropdown.Item>
          <NavDropdown.Item href="/profile/history">
            History & Recordings
          </NavDropdown.Item>
          <NavDropdown.Item href="/profile/invoices">
            Invoice History
          </NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item href="/support">Support Center</NavDropdown.Item>
          <NavDropdown.Item href="/logout">Log Out</NavDropdown.Item>
        </NavDropdown>
      </div>
      <Navbar.Collapse id="basic-navbar-nav" className="order-lg-1">
        <Nav className="main-dropdown">
          <Nav.Link href="/profile">Account Dashboard</Nav.Link>
          <NavDropdown
            title="Online Meetings"
            id="basic-nav-dropdown"
            className="d-none d-lg-block"
          >
            <NavDropdown.Item href={`/wall/${meeting_url}/host/broadcast`}>
              Broadcaster
            </NavDropdown.Item>
            <NavDropdown.Item href={`/wall/${meeting_url}/host/web_controls`}>
              Web Controls
            </NavDropdown.Item>
            <NavDropdown.Item href={`/wall/${meeting_url}`}>
              Go to Meeting Wall
            </NavDropdown.Item>
            <NavDropdown.Item href="/profile/settings?tab=wall-editor">
              Go to Wall Editor
            </NavDropdown.Item>
            <NavDropdown.Item href="/profile/learn-more-online-meeting">
              Learn More
            </NavDropdown.Item>
          </NavDropdown>

          <div className="d-lg-none">
            <Nav.Link href={`/wall/${meeting_url}/host/broadcast`}>
              Broadcaster
            </Nav.Link>
            <Nav.Link href={`/wall/${meeting_url}/host/web_controls`}>
              Web Controls
            </Nav.Link>
            <Nav.Link href={`/wall/${meeting_url}`}>Go to Wall Editor</Nav.Link>
            <Nav.Link href="/profile/settings?tab=wall-editor">
              Go to Meeting Wall
            </Nav.Link>
            <Nav.Link href="/profile/learn-more-online-meeting">
              Learn More
            </Nav.Link>
            <NavDropdown.Divider />
            <Nav.Link href={host_url}>Host Meeting</Nav.Link>
            <Nav.Link
              href="#"
              role="button"
              onClick={(event) => {
                event.preventDefault();
                openJoinModal();
              }}
            >
              Join Meeting
            </Nav.Link>
            <Nav.Link href="/profile/invite">Schedule Meeting</Nav.Link>
          </div>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
