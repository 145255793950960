import { Outlet } from "react-router-dom";
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// import { ScrollRestoration } from 'react-router-dom';

import MainNavigation from "../components/MainNavigation";
import Footer from "../components/Footer";

export function ScrollRestoration() {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    // Standard scroll restoration for regular navigation
    window.scrollTo(0, 0);

    // Handle hash links on direct page load
    if (hash) {
      const scrollToHash = () => {
        const element = document.getElementById(hash.substring(1));
        if (element) {
          const headerHeight = document.querySelector('header')?.clientHeight || 0;
          const y = element.getBoundingClientRect().top + window.scrollY - headerHeight;

          // Immediate scroll attempt
          window.scrollTo({ top: y, behavior: 'instant' });

          // Delayed scroll for dynamic content (if needed)
          setTimeout(() => {
            window.scrollTo({ top: y, behavior: 'instant' });
          }, 300);
        }
      };

      // Try multiple times to account for various load states
      scrollToHash();
      const timeout1 = setTimeout(scrollToHash, 100);
      const timeout2 = setTimeout(scrollToHash, 500);

      return () => {
        clearTimeout(timeout1);
        clearTimeout(timeout2);
      };
    }
  }, [pathname, hash]);

  return null;
}

function RootLayout() {
  return (
    <>
      <ScrollRestoration />
      <MainNavigation />
      <main>
        <Outlet />
      </main>
      <Footer />
    </>
  );
}

export default RootLayout;
