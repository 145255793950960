import SessionProvider from "./lib/SessionProvider";
import ModalProvider from "./lib/ModalProvider";
import FlashMessageProvider from "./lib/FlashMessageProvider";
import { HelmetProvider } from "react-helmet-async";
import TrackingScripts from "./lib/TrackingScripts";
import LiveChat from "./lib/LiveChat";

import FCCRouter from "./lib/FCCRouter";

export default function App() {
  return (
    <SessionProvider>
      <FlashMessageProvider>
        <ModalProvider>
          <HelmetProvider>
            <TrackingScripts />
            <LiveChat />
            <FCCRouter />
          </HelmetProvider>
        </ModalProvider>
      </FlashMessageProvider>
    </SessionProvider>
  );
}
