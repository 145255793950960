import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { lazy, Suspense, useEffect } from "react";
import RootLayout from "../pages/Root";
import { loadRates, loadData } from "../lib/helpers";

// const ErrorPage lazy(() => import("../pages/Error"));
const HomePage = lazy(() => import("../pages/Home"));
const PricingPage = lazy(() => import("../pages/Pricing"));
const AboutusPage = lazy(() => import("../pages/Aboutus"));
const FeaturesPage = lazy(() => import("../pages/Features"));
const YourImpactPage = lazy(() => import("../pages/YourImpact"));
const DownloadsPage = lazy(() => import("../pages/Downloads"));
const PWYCPage = lazy(() => import("../pages/PWYC"));
const UpgradesPage = lazy(() => import("../pages/Upgrades"));

const RedirectTo404 = () => {
  useEffect(() => {
    window.location.replace("/page-not-found");
  }, []);
  return null;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <RedirectTo404 />,
    children: [
      { path: "/", element: <HomePage /> },
      { path: "/downloads", element: <DownloadsPage /> },
      { path: "/pwyc", element: <PWYCPage />, loader: loadData },
      { path: "/upgrades", element: <UpgradesPage />, loader: loadData },
      { path: "/aboutus", element: <AboutusPage /> },
      { path: "/features", element: <FeaturesPage /> },
      { path: "/pricing", element: <PricingPage />, loader: loadRates },
      {
        path: "/how-people-use-free-conference-call",
        element: <YourImpactPage />,
      },
    ],
  },
]);

export default function FCCRouter() {
  return (
    <Suspense fallback={null}>
      <RouterProvider router={router} />
    </Suspense>
  );
}
